// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_5mJ\\+z{width:100%}.page__body_zIXT1{padding:16px}.seller_cQLfn{width:100% !important;margin-bottom:24px}.products_TucQA{margin-top:22px}.search_yEA0W{background:#141e2b;position:sticky;top:48px;padding:10px 0;z-index:1}@media(min-width: 768px){.search_yEA0W{top:78px}}.banner_j3Ai\\+{display:block;width:100%;margin:20px 0;border-radius:16px;background-color:#1c2735;overflow:hidden}.banner__image_vGOZv{display:block;width:100%}.banner__imageMob_TrTNM{display:block;width:100%}@media(min-width: 768px){.banner__imageMob_TrTNM{display:none}}.bestsellers_qLZ5k{overflow:auto;width:100%;min-width:160px;margin:20px 0;border-radius:10px;padding:20px 16px;background:linear-gradient(262deg, #3949AB 5.62%, #5C6BC0 95.25%)}.bestsellers_qLZ5k::-webkit-scrollbar{width:0;outline:none;padding:0}.bestsellers_qLZ5k::-webkit-scrollbar-track{width:8px;background:rgba(0,0,0,0);padding:0 4px}.bestsellers_qLZ5k::-webkit-scrollbar-thumb{background:rgba(0,0,0,0);border-radius:2px;border-left:4px solid rgba(0,0,0,0);border-right:4px solid rgba(0,0,0,0)}@media(min-width: 768px){.bestsellers__top_DsfeL{display:flex;align-items:center;justify-content:space-between}}.bestsellers__container_S68vC{background:rgba(0,0,0,0);display:grid;grid-template-columns:repeat(5, 250px);grid-gap:8px;width:100%;overflow-x:auto}@media(min-width: 1300px){.bestsellers__container_S68vC{grid-template-columns:repeat(5, 1fr);grid-gap:16px}}.bestsellers__button_KGkKK{margin:0 0 20px auto;background:rgba(0,0,0,0) !important;border:2px solid #fff}.bestsellersCard_etZG0{max-width:250px}.bestsellers__title_LChmc{width:100%;color:#fff;font-size:24px;font-weight:700;line-height:32px;margin:0 0 24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "page_5mJ+z",
	"page__body": "page__body_zIXT1",
	"seller": "seller_cQLfn",
	"products": "products_TucQA",
	"search": "search_yEA0W",
	"banner": "banner_j3Ai+",
	"banner__image": "banner__image_vGOZv",
	"banner__imageMob": "banner__imageMob_TrTNM",
	"bestsellers": "bestsellers_qLZ5k",
	"bestsellers__top": "bestsellers__top_DsfeL",
	"bestsellers__container": "bestsellers__container_S68vC",
	"bestsellers__button": "bestsellers__button_KGkKK",
	"bestsellersCard": "bestsellersCard_etZG0",
	"bestsellers__title": "bestsellers__title_LChmc"
};
module.exports = ___CSS_LOADER_EXPORT___;
